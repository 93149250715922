import { graphql } from 'gatsby'
import { NotFound } from '@/components'
export const pageQuery = graphql`
  query NotFound($lang: String!) {
    prismic404(lang: { eq: $lang }) {
      data {
        back_home_link_text {
          text
        }
        seo_title {
          text
        }
        description {
          text
        }
        title {
          text
        }
      }
      lang
    }
  }
`
export default NotFound
